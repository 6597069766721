/* index.css */

/* AuthWrapper のスタイル */
.auth-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.auth-wrapper .login-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.auth-wrapper .login-container input[type="text"],
.auth-wrapper .login-container input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.auth-wrapper .login-container button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.auth-wrapper .login-container button:hover {
  background-color: #45a049;
}

.auth-wrapper .error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}
