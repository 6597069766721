/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3 {
  color: #2c3e50;
}

h1 {
  text-align: center;
  margin-bottom: 30px;
}

button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
}

.processing-section {
  margin-bottom: 20px;
}

.datetime-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.datetime-section label {
  margin-right: 10px;
}

.datetime-section input[type="datetime-local"] {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

pre {
  background-color: #ecf0f1;
  padding: 15px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.result-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-section, .map-section {
  width: 48%;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.tab-buttons {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.tab-button {
  background-color: #ecf0f1;
  color: #2c3e50;
  border: none;
  padding: 10px 15px;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #3498db;
  color: white;
}

.image-display {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecf0f1;
  margin-bottom: 15px;
}

.image-display img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.slider-container {
  text-align: center;
}

.slider-container input[type="range"] {
  width: 100%;
  margin-bottom: 10px;
}

.map-container {
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
}

.styled-page-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.styled-page-content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.email-form {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.email-form input, .email-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.email-form button {
  width: 100%;
  padding: 10px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.email-form button:hover {
  background-color: #27ae60;
}

@media (max-width: 768px) {
  .result-container {
    flex-direction: column;
  }

  .image-section, .map-section {
    width: 100%;
  }
}

.email-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.email-form h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
}

.email-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.email-form button:hover {
  background-color: #0056b3;
}
.result-container {
  display: flex;
  flex-direction: column;
}

.result-map-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.image-section, .map-section {
  width: 48%;
}

@media (max-width: 768px) {
  .result-map-container {
    flex-direction: column;
  }

  .image-section, .map-section {
    width: 100%;
  }
}

.folder-value {
  font-size: 0.8em;
  color: #666;
  margin-left: 5px;
}

.slide-value {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
} 

.image-display {
  position: relative;
}

.slide-value {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}